<template>
	<div class="page">
		<Head />
		<!-- <div class="page-banner" :style="{'background-image': 'url('+banner+')'}"></div> -->
		<div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
		<div class="product-tab company-tab">
			<div class="container">
				
			</div>
		</div>
		<div v-for="(item,index) in pageitems" :key="index" class="{'z-warp': item.item_type == 'left', 'hot-product': item.item_type == 'Three_column', 'm-warp':item.item_type == 'analysis'}, 'section':item.item_type == 'picture'">
			<div class="container" v-if="item.item_type == 'left'">
				<div class="flex company-info">
					<div  class="company-info-content">
						<div class="company-info-tit">{{item.title}} </div>
						<div class="company-info-desc" >
							<div v-html="item.content"></div>
						</div>						
					</div>
					<div class="flex_bd  company-info-pic">
						<img :src="item.pic[0]">
					</div>
				</div>
			</div>
			<div class="section" v-if="item.item_type == 'picture'">
				<div class="section-head">
					<div  class="section-title">{{item.title}}</div>
					<div class="sec-line"></div>
				</div>
				<div class="section-body">
					<div class="container">
						<div class="com-box">
							<div class="clearfix">
								
								<div class="com-lt pull-left">
									<div class="com-pic">
										<img :src="item.pic[0]">
									</div>
									<el-row :gutter="29">
										<el-col :span="42">
											<div class="com-pic">
												<img :src="item.pic[1]">
											</div>
										</el-col>
										<el-col :span="58">
											<div class="com-pic">
												<img :src="item.pic[2]">
											</div>
										</el-col>
									</el-row>
								</div>
								<div class="com-rt pull-right">
									<div class="com-pic">
										<img v-if="lang=='en-US'" :src="item.pic[3]">
										<img v-if="lang=='zh-CN'" :src="item.pic[4]">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot :nvlang="lang"/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import {
		mapState
		} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				// lang:'',
				left_fixed: false,
				inside:{
					// pic1:require('../assets/images/h1.jpg'),
					// pic2:require('../assets/images/h2.jpg'),
					// pic3:require('../assets/images/h3.jpg'),
					// pic4:require('../assets/images/h4.jpg'),
				},
				active: 0,
				banner: require('../assets/images/about_banner.jpg'),
				conmpany: {
					// title: '迈思康科技',
					// title_en: 'MaxComm Tech',
					// desc: ['成立于2018年7月，注册资本600万元人民币，迈思康科技是SGS  ISO 9000认证工厂',
					// 	'专注于设计及制造精密冲压模具、精密五金件冲压。',
					// 	'产品广泛应用于精密连接器端子，继电器弹片，微型马达弹片，IC引线框架，家电精密金属零件，其他电子元件及医疗设备零件，新能源汽车等行业。',
					// 	'具有研发及生产各类精密五金产品及精密冲压模具的能力',
					// 	'迈思康科技致力为客户提供专业可靠的精密五金零件及增值服务'
					// ],
					// desc_en: ['Founded in July 2018 with an initial registered capital of USD 1.1 million',
					// 	'MaxComm Technology is an ODM/OEM manufacturer.',
					// 	'specializing in designing and manufacturing precision stamped and formed parts and tools.',
					// 	'Equipped with strong R & D engineering capability, in house tool design and fabrication, coupling with robust ISO 9001:2015 quality system',
					// 	'We are set to provide our valuable customers with high quality products, quick turnaround, design to cost solutions and value-added services'
					// ],
					// pic: require('../assets/images/ab00.png')
				},
				swiperOptionTop: {
					loop: true,
					loopedSlides: 1, // looped slides should be the same
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				com:{
					// pic1:require('../assets/images/sgs.png'),
					// pic2:require('../assets/images/ab01.png'),
					// pic3:require('../assets/images/ab02.png'),
					// pic4:require('../assets/images/ab03.png'),
					// pic5:require('../assets/images/sgs-cn.png'),
				}
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'AboutUs')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}		
				
			},
			slide(){
				var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				if(slide){
					return slide[0].pictures.split(',');
				}else{
					return [this.banner]
				}
				
			},
			pageitems(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (var it of itemlist){
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					}else if(it.item_type !='picture' && it.item_type !='analysis'){
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it.pictures){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			// langchange(data){
			// 	this.lang = data;
			// },
			stepClick(index) {
				this.active = index
				// console.log(index)
			},
			changeActive(index) {
				for (let i = 0; i < this.culture.length; i++) {
					this.culture[i].isActive = false;
					this.culture[index].isActive = true;
				}
			},
			// handleClick(tab, event) {
			// 	console.log(tab, event);
			// 	console.log(tab.name);
			// }
		}
	}
</script>
